const calculateRMS = (audioBuffer: AudioBuffer) => {
  const channelData = audioBuffer.getChannelData(0);
  let sumOfSquares = 0;
  for (let i = 0; i < channelData.length; i++) {
    sumOfSquares += channelData[i] * channelData[i];
  }
  const rms = Math.sqrt(sumOfSquares / channelData.length);
  return rms;
};

export const getIsAudioSilent = (
  audioBuffer: AudioBuffer,
  threshold: number = 0.01,
) => {
  const rms = calculateRMS(audioBuffer);
  return rms < threshold;
};

enum NativeLanguageFrequency {
  NONE = 'None',
  SOME = 'Some',
}

enum CorrectionFrequency {
  NEVER = 'Never',
  SOMETIMES = 'Sometimes',
  ALWAYS = 'Always',
}

const getNativeLanguageInstructions = (
  language: string,
  nativeLanguage: string,
  nativeLanguageFrequency: string,
) => {
  if (nativeLanguageFrequency === NativeLanguageFrequency.NONE) {
    return 'Always communicate in ' + language + '.';
  }
  return `Use the user's native language, ${nativeLanguage}, as needed to explain things, but mostly stay in ${language}.`;
};

export const generateUserPreferencesPrompt = (
  userName: string,
  level: string,
  language: string,
  correctionFrequency: string,
  userNativeLanguage: string,
  nativeLanguageFrequency: string,
) => {
  /**
   * notes on user preferences
   * 
   //consider "A1 Breakthrough", "A2 Waystage", etc  https://en.wikipedia.org/wiki/Common_European_Framework_of_Reference_for_Languages

   * ideas:
   * - "Mostly B1, but introduce B2 elements"
   * - ???Start with mostly B1, slowly introducing more B2 elements over time.
   *        Sample System Prompt with "Every 5 Messages" Rule:
                "The user is currently at a B1 level. You should primarily use B1 language, but after every 5 responses from the user, 
                introduce a B2 element (new vocabulary, more complex sentence structures, or modals) gradually to challenge them. 
                If the user shows confidence and uses more complex structures, introduce these B2 elements earlier. Adjust the pacing as needed, 
                but aim to keep things natural and engaging without overwhelming the user."
   */

  const levelInstructions =
    level !== 'C2'
      ? `Mostly use language at this level, but occasionally incorporate elements of the next level up to help the user learn.`
      : 'Use language at this level.';

  const correctionFrequencyInstructions =
    correctionFrequency === CorrectionFrequency.NEVER
      ? 'Never correct the user when they make a mistake.'
      : correctionFrequency === CorrectionFrequency.SOMETIMES
      ? 'Correct the user ocassionally when they make a mistake, but not too often.'
      : 'Always correct the user when they make a mistake.';

  const userPreferencesPrompt = `- Name: ${userName}
- CEFRL level: ${level}. ${levelInstructions}
- ${correctionFrequencyInstructions}
- ${getNativeLanguageInstructions(
    language,
    userNativeLanguage,
    nativeLanguageFrequency,
  )}`;

  return userPreferencesPrompt;
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getFlag = (language: string) => {
  switch (language) {
    case 'English':
      return '🇺🇸';
    case 'Spanish':
      return '🇪🇸';
    case 'French':
      return '🇫🇷';
    case 'German':
      return '🇩🇪';
    case 'Italian':
      return '🇮🇹';
    case 'Portuguese':
      return '🇵🇹';
    case 'Russian':
      return '🇷🇺';
    case 'Chinese':
      return '🇨🇳';
    default:
      return '';
  }
};

export const getFlagAndLanguage = (language: string) => {
  // return `${getFlag(language)} ${language}`;
  return language;
};
