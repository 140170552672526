import { Dispatch, RefObject, SetStateAction } from 'react';
import { MESSAGE_ROLES } from '../utils/constants';
import Message from './Message';

interface IMessage {
  role: string;
  content: string;
  transliteration: string | null;
  _id: string;
}

interface MessagesProps {
  messages: IMessage[];
  messagesEndRef: RefObject<HTMLDivElement>;
  stopAllAudios: () => void;
  audioMap: Record<string, HTMLAudioElement>;
  setAudioMap: Dispatch<SetStateAction<Record<string, HTMLAudioElement>>>;
  setPlayingStateMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  playingStateMap: Record<string, boolean>;
}

export const MESSAGES_CONTAINER = 'messages-container';

const Messages = ({
  messages,
  messagesEndRef,
  stopAllAudios,
  audioMap,
  setAudioMap,
  playingStateMap,
  setPlayingStateMap,
}: MessagesProps) => {
  return (
    <div
      id={MESSAGES_CONTAINER}
      className='w-full h-full flex flex-col overflow-y-auto p-2 lg:w-3/4 xl:w-3/5 2xl:w-2/5 mx-auto flex-grow'
    >
      {messages.map(({ role, content, transliteration, _id }, i) => {
        const isLastAIMessage =
          i === messages.length - 1 && role === MESSAGE_ROLES.ASSISTANT;
        const isTypingAnimation = role === MESSAGE_ROLES.TYPING_ANIMATION;
        const isUser = role === MESSAGE_ROLES.USER;
        const isFirstMessage = i === 0;

        return (
          <Message
            {...{
              role,
              content,
              transliteration,
              _id,
              stopAllAudios,
              audioMap,
              setAudioMap,
              playingStateMap,
              setPlayingStateMap,
              isLastAIMessage,
              isTypingAnimation,
              isUser,
              isFirstMessage,
              key: _id || i,
            }}
          />
        );
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default Messages;
