import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
// import { useUserContext } from '../contexts/UserContext';
import { PATHS, STEPS_SELECTOR_CLASSES } from '../utils/constants';
// import { getFlag } from '../utils/utils';
// import useIsWindows from '../hooks/useIsWindows';

interface ChangeLanguageOrTeacherButtonProps {
  stopAllAudios: () => void;
  setPlayingStateMap: Dispatch<SetStateAction<Record<string, boolean>>>;
}

const ChangeLanguageOrTeacherButton = ({
  stopAllAudios,
  setPlayingStateMap,
}: ChangeLanguageOrTeacherButtonProps) => {
  const navigate = useNavigate();
  // const { user } = useUserContext();
  // const isWindows = useIsWindows();

  return (
    <button
      onClick={() => {
        setPlayingStateMap({});
        stopAllAudios();
        navigate(PATHS.TEACHERS);
      }}
      className={`flex gap-x-1 p-4 ${STEPS_SELECTOR_CLASSES.CHANGE_LANGUAGE_OR_TEACHER_BUTTON}`}
    >
      <SwitchAccountIcon fontSize='small' />
      <p>/</p>
      {/* {isWindows ? <LanguageIcon /> : <p>{getFlag(user.selectedLanguage)}</p>} */}
      <LanguageIcon fontSize='small' />
    </button>
  );
};

export default ChangeLanguageOrTeacherButton;
