import { PropsWithChildren, useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { EVENTS } from '../utils/constants';
import useIsTabletOrLarger from '../hooks/useIsTabletOrLarger';
import { useWidgetContext } from '../contexts/WidgetContext';
// import CustomBottomNav from './CustomBottomNav';
import NavSlider from './NavSlider';
import FeedbackTakeover from './FeedbackTakeover';
import PersonalizationTakeover from './PersonalizationTakeover';

const FEEDBACK_TAKEOVER = 'feedback-takeover';
const PERSONALIZATION_TAKEOVER = 'personalization-takeover';

const Layout = ({ children }: PropsWithChildren) => {
  const { isNavSliderOpen } = useWidgetContext();
  // const { pathname } = useLocation();
  const [showFeedbackTakeover, setShowFeedbackTakeover] = useState(false);
  const [showPersonalizationTakeover, setShowPersonalizationTakeover] =
    useState(false);
  const isTabletOrLarger = useIsTabletOrLarger();

  // const shouldShowBottomNav = [
  //   PATHS.PROFILE,
  //   PATHS.REVIEW,
  //   PATHS.ROLE_PLAY,
  //   PATHS.LESSONS,
  //   PATHS.TEACHERS,
  // ].includes(pathname);

  /**
   * Handle feedback and custom instructions takeovers blur
   */
  useEffect(() => {
    if (!isTabletOrLarger) return;

    const handleBlur = (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === FEEDBACK_TAKEOVER) {
        setShowFeedbackTakeover(false);
      }

      if ((e.target as HTMLElement).id === PERSONALIZATION_TAKEOVER) {
        setShowPersonalizationTakeover(false);
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [isTabletOrLarger]);

  return (
    <div className='h-screen w-screen relative'>
      {isNavSliderOpen && (
        <NavSlider
          {...{
            setShowFeedbackTakeover,
            setShowPersonalizationTakeover,
          }}
        />
      )}
      {children}
      {showFeedbackTakeover && (
        <FeedbackTakeover
          {...{
            id: FEEDBACK_TAKEOVER,
            closeFeedbackTakeover: () => setShowFeedbackTakeover(false),
          }}
        />
      )}
      {showPersonalizationTakeover && (
        <PersonalizationTakeover
          {...{
            id: PERSONALIZATION_TAKEOVER,
            closePersonalizationTakeover: () =>
              setShowPersonalizationTakeover(false),
          }}
        />
      )}
      {/* {shouldShowBottomNav && <CustomBottomNav />} */}
    </div>
  );
};

export default Layout;
