import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useUserContext } from '../contexts/UserContext';
import { SIZES } from '../utils/constants';
import { useConfigContext } from '../contexts/ConfigContext';
import { useWidgetContext } from '../contexts/WidgetContext';
import ChatInputForm from './ChatInputForm';
import Messages from './Messages';
import Spinner from './Spinner';
import TopicSuggestions from './TopicSuggestions';
import toast from 'react-hot-toast';

interface ChatContainerProps {
  stopAllAudios: () => void;
  audioMap: Record<string, HTMLAudioElement>;
  setAudioMap: Dispatch<SetStateAction<Record<string, HTMLAudioElement>>>;
  setPlayingStateMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  playingStateMap: Record<string, boolean>;
  messages: any[]; //fixme
  areInputButtonsDisabled: boolean;
  latestAudioMessageId: string;
  handleTextSubmit: (textValue: string) => Promise<void>;
  handleMP3Submit: (mp3Blob: Blob) => Promise<void>;
}

const ChatContainer = ({
  stopAllAudios,
  audioMap,
  setAudioMap,
  setPlayingStateMap,
  playingStateMap,
  messages,
  areInputButtonsDisabled,
  latestAudioMessageId,
  handleTextSubmit,
  handleMP3Submit,
}: ChatContainerProps) => {
  const { user, settingsMap } = useUserContext();
  const { config } = useConfigContext();
  const { isTopicSuggestionsOpen, closeTopicSuggestions } = useWidgetContext();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { showTransliteration } = settingsMap[user.selectedLanguage];

  /**
   * Scroll to bottom if messages change or when `showTransliteration` is toggled
   */
  useEffect(() => {
    if (messages.length > 1) scrollToBottom();
  }, [messages.length, showTransliteration]);

  /**
   * Auto play latest audio
   */
  useEffect(() => {
    if (!user.isAutoPlayOn) return;

    const audioToPlay = audioMap[latestAudioMessageId];

    if (!audioToPlay) return;

    stopAllAudios();
    setPlayingStateMap({ [latestAudioMessageId]: true });
    audioToPlay.play().catch(e => {
      console.log('Error playing audio: ', e);
      toast.error('Sorry, error playing audio.');
      setPlayingStateMap({});
    });

    audioToPlay.onended = () => setPlayingStateMap({});

    // note user.isAutoPlayOn being in dep arr causes weird thing if you unmute teacher itll play last message)..not sure if others should be or not
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestAudioMessageId, stopAllAudios]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleTopicClick = (text: string) => {
    handleTextSubmit(text);
    stopAllAudios();
    setPlayingStateMap({});
    scrollToBottom();
  };

  return (
    <div className='h-full flex flex-col pt-20'>
      {messages.length > 0 ? (
        <>
          <Messages
            {...{
              messages,
              messagesEndRef,
              stopAllAudios,
              audioMap,
              setAudioMap,
              playingStateMap,
              setPlayingStateMap,
            }}
          />
          <>
            {config.featureFlags.features.topicSuggestions && (
              <TopicSuggestions
                open={isTopicSuggestionsOpen}
                closeTopicSuggestions={closeTopicSuggestions}
                handleTopicClick={handleTopicClick}
                isDisabled={areInputButtonsDisabled}
              />
            )}
          </>

          <ChatInputForm
            {...{
              handleTextSubmit,
              handleMP3Submit,
              areInputButtonsDisabled,
              stopAllAudios,
              setPlayingStateMap,
              scrollToBottom,
            }}
          />
        </>
      ) : (
        <div className='m-auto'>
          <Spinner size={SIZES.LARGE} />
        </div>
      )}
    </div>
  );
};

export default ChatContainer;
