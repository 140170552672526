import { useState, MouseEvent, useEffect } from 'react';
import translationAPI from '../api/translationAPI';
import { useUserContext } from '../contexts/UserContext';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import WordTranslationPopover from './WordTranslationPopover';
import { useFlashCardsContext } from '../contexts/FlashCardsContext';
import flashCardsAPI from '../api/flashCardsAPI';
const removePunctuation = (word: string) => word.replace(/[¿?!¡.,]/g, '');

interface WordProps {
  word: string;
  isUser: boolean;
}

/**
 * @note For use in `Message.tsx`
 */
const Word = ({ word, isUser }: WordProps) => {
  const { flashCardsByWordMap, setFlashCards, flashCards } =
    useFlashCardsContext();
  const { _id: userId, nativeLanguage } = useUserContext().user;
  const [cleanedWord] = useState(removePunctuation(word.toLowerCase()));
  const [wordTranslation, setWordTranslation] = useState(
    flashCardsByWordMap[cleanedWord]?.translation || '',
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isBookmarked, setIsBookmarked] = useState(
    flashCardsByWordMap[cleanedWord] !== undefined,
  );
  const [isFetchingBookmarkStatus, setIsFetchingBookmarkStatus] =
    useState(false);

  useEffect(() => {
    setIsBookmarked(flashCardsByWordMap[cleanedWord] !== undefined);
  }, [flashCardsByWordMap, cleanedWord]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleWordClick = async (event: MouseEvent<HTMLButtonElement>) => {
    handlePopoverOpen(event);

    if (wordTranslation) return;

    try {
      const translation = await translationAPI.fetchTranslation(
        nativeLanguage,
        cleanedWord,
      );

      setWordTranslation(removePunctuation(translation));
    } catch (error) {
      toast.error('Error fetching translation. Please try again.');
      handlePopoverClose();
    }
  };

  const handleBookmarkToggle = async () => {
    setIsFetchingBookmarkStatus(true);

    if (isBookmarked) {
      await flashCardsAPI.deleteFlashCards(userId, [
        flashCardsByWordMap[cleanedWord]._id,
      ]);

      setFlashCards(
        flashCards.filter(
          flashCard => flashCard._id !== flashCardsByWordMap[cleanedWord]._id,
        ),
      );
    } else {
      const newFlashCard = await flashCardsAPI.addFlashCard(
        userId,
        cleanedWord,
        wordTranslation,
      );

      setFlashCards([...flashCards, newFlashCard]);
    }

    setIsFetchingBookmarkStatus(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <span className='inline-flex items-center'>
      <ClickableWord word={word} isUser={isUser} onClick={handleWordClick} />
      &nbsp;
      {!isUser && (
        <WordTranslationPopover
          {...{
            id,
            open,
            anchorEl,
            wordTranslation,
            cleanedWord,
            isBookmarked,
            isFetchingBookmarkStatus,
            onClose: handlePopoverClose,
            onBookmarkToggle: handleBookmarkToggle,
          }}
        />
      )}
    </span>
  );
};

interface ClickableWordProps {
  word: string;
  isUser: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const ClickableWord = ({ word, isUser, onClick }: ClickableWordProps) => (
  <span
    onClick={!isUser ? onClick : undefined}
    className={classNames(
      !isUser && 'cursor-pointer relative hover:text-blue-800',
    )}
  >
    {word}
  </span>
);

export default Word;
