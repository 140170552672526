import {
  useContext,
  useState,
  createContext,
  PropsWithChildren,
  useEffect,
} from 'react';
import { useUserContext } from './UserContext';
import flashCardsAPI from '../api/flashCardsAPI';

export enum FlashCardDifficulty {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

export interface FlashCard {
  _id: string;
  userId: string;
  word: string;
  translation: string;
  mnemonic: string;
  imageUrl: string;
  audioUrl: string;
  exampleSentence: string;
  exampleSentenceTranslation: string;
  lastReviewed: Date;
  difficulty: FlashCardDifficulty;
}

interface IFlashCardsContext {
  flashCards: FlashCard[];
  setFlashCards: (flashCards: FlashCard[]) => void;
  flashCardsByWordMap: Record<string, FlashCard>;
  isFetchingFlashCards: boolean;
  isErrorFetchingFlashCards: boolean;
}

interface IFlashCardsByWordMap {
  [key: string]: FlashCard;
}

const FlashCardsContext = createContext<IFlashCardsContext>({
  flashCards: [],
  setFlashCards: () => {},
  flashCardsByWordMap: {},
  isFetchingFlashCards: true,
  isErrorFetchingFlashCards: false,
});

export const useFlashCardsContext = () => useContext(FlashCardsContext);

export const FlashCardsProvider = ({ children }: PropsWithChildren) => {
  const { _id: userId } = useUserContext().user;
  const [flashCards, setFlashCards] = useState<FlashCard[]>([]);
  const [flashCardsByWordMap, setFlashCardsByWordMap] =
    useState<IFlashCardsByWordMap>({});
  const [isFetchingFlashCards, setIsFetchingFlashCards] = useState(true);
  const [isErrorFetchingFlashCards, setIsErrorFetchingFlashCards] =
    useState(false);

  useEffect(() => {
    if (!userId) return;

    const fetchFlashCards = async () => {
      try {
        const flashCards = await flashCardsAPI.fetchFlashCards(userId);
        setFlashCards(flashCards);
      } catch (error) {
        console.error('Error fetching flashcards:', error);
        setIsErrorFetchingFlashCards(true);
      }
    };

    fetchFlashCards();
  }, [userId]);

  useEffect(() => {
    const flashCardsByWordMap = flashCards.reduce((acc, card) => {
      acc[card.word] = card;
      return acc;
    }, {} as IFlashCardsByWordMap);

    setFlashCardsByWordMap(flashCardsByWordMap);

    if (isFetchingFlashCards) {
      setIsFetchingFlashCards(false);
    }
  }, [flashCards, isFetchingFlashCards]);

  return (
    <FlashCardsContext.Provider
      value={{
        flashCards,
        setFlashCards,
        flashCardsByWordMap,
        isFetchingFlashCards,
        isErrorFetchingFlashCards,
      }}
    >
      {children}
    </FlashCardsContext.Provider>
  );
};
