import { ICustomInstructions, UserFact } from '../../contexts/UserContext';

export interface Settings {
  language: string;
  level: string;
  motivation: string;
  correctionFrequency: string;
  speed?: string;
  nativeLanguageFrequency: string;
  showTransliteration?: boolean;
  selectedTeacher?: string;
  textToTextModel?: string;
}

export interface UpdateUserArgs {
  userEmail: string;
  userName?: string;
  selectedLanguage?: string;
  nativeLanguage?: string;
  isAutoPlayOn?: boolean;
  settings?: Settings;
  isNewUser?: boolean;
  hasUserSeenTutorial?: boolean;
  isMicrophoneOnRight?: boolean;
  customInstructions?: ICustomInstructions;
  userFacts?: UserFact[];
}

export const allowedLanguages = [
  'English',
  'Spanish',
  'French',
  'German',
  'Russian',
  'Italian',
  'Portuguese',
  'Chinese',
]; // todo Add all supported languages

export type Language = (typeof allowedLanguages)[number];

export const allowedLevels = ['Beginner', 'Intermediate', 'Advanced'];

export type Level = (typeof allowedLevels)[number];

export const allowedCorrectionFrequencyOptions = [
  'Never',
  'Sometimes',
  'Always',
];

export type CorrectionFrequency =
  (typeof allowedCorrectionFrequencyOptions)[number];

export const allowedSpeeds = ['Slow', 'Normal'];

export type Speed = (typeof allowedSpeeds)[number];

export const allowedNativeLanguageFrequencyOptions = ['None', 'Some'];

export type NativeLanguageFrequency =
  (typeof allowedNativeLanguageFrequencyOptions)[number];

interface LanguageSettings {
  language: Language;
  level: Level;
  motivation: string;
  correctionFrequency: CorrectionFrequency;
  speed?: Speed; //todo this is dead code until add back to language settings takeover
  nativeLanguageFrequency: NativeLanguageFrequency;
  selectedTeacher: string;
}

export interface User {
  userName: string;
  email: string;
  nativeLanguage: string;
  selectedLanguage: Language;
  settings: LanguageSettings[];
  lastLoggedIn: Date;
  isAutoPlayOn: Boolean;
}

//TODO clean up file, DRY
