import { UserFact } from '../contexts/UserContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef } from 'react';

const YourFacts = ({
  editingFactIndex,
  setEditingFactIndex,
  editingFactContent,
  setEditingFactContent,
  handleEditFact,
  handleDeleteFact,
  userFacts,
}: {
  editingFactIndex: number | null;
  setEditingFactIndex: (index: number | null) => void;
  editingFactContent: string;
  setEditingFactContent: (content: string) => void;
  handleEditFact: (index: number) => void;
  handleDeleteFact: (index: number) => void;
  userFacts: UserFact[];
}) => {
  const isEditingFact = editingFactIndex !== null;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.selectionStart = textAreaRef.current.value.length;
      textAreaRef.current.selectionEnd = textAreaRef.current.value.length;
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [editingFactIndex]);

  return (
    <div className='space-y-3'>
      <h3 className='text-lg font-semibold text-gray-800'>Your Facts</h3>
      <p className='text-sm text-gray-600'>
        Your tutor will save facts to customize your lessons.
      </p>
      {!userFacts.length && (
        <p className='text-sm text-gray-600'>No facts saved yet.</p>
      )}

      <div className='grid grid-cols-2 sm:grid-cols-3 gap-2 max-h-40 overflow-y-auto px-2'>
        {userFacts.reverse().map((fact, index) => (
          <div
            key={index}
            className='relative inline-flex bg-white rounded-lg px-3 py-2 border border-gray-200 shadow-sm hover:shadow-md transition-all'
          >
            {editingFactIndex === index ? (
              <div className='pr-9'>
                <textarea
                  ref={textAreaRef}
                  value={editingFactContent}
                  onChange={e => setEditingFactContent(e.target.value)}
                  className='w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none'
                  autoFocus
                />
                <div className='absolute top-0 right-0'>
                  <button
                    type='button'
                    onClick={() => {
                      setEditingFactIndex(null);
                      setEditingFactContent('');
                    }}
                    className='p-1 text-gray-400 hover:text-gray-600'
                    title='Cancel'
                  >
                    <CloseIcon style={{ fontSize: '16px' }} />
                  </button>
                  <button
                    type='button'
                    onClick={() => handleEditFact(index)}
                    className='p-1 text-blue-500 hover:text-blue-700'
                    title='Save'
                  >
                    <CheckIcon style={{ fontSize: '16px' }} />
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className='text-gray-800 text-sm pr-8 line-clamp-2'>
                  {fact.content}
                </p>
                <div className='absolute top-0 right-0'>
                  <button
                    type='button'
                    onClick={() => {
                      setEditingFactIndex(index);
                      setEditingFactContent(fact.content);
                    }}
                    className={`p-1 ${
                      isEditingFact
                        ? 'text-gray-200 cursor-not-allowed'
                        : 'text-gray-400 hover:text-gray-600'
                    }`}
                    title='Edit fact'
                    disabled={isEditingFact}
                  >
                    <EditIcon style={{ fontSize: '16px' }} />
                  </button>
                  <button
                    type='button'
                    onClick={() => handleDeleteFact(index)}
                    className={`p-1 ${
                      isEditingFact
                        ? 'text-gray-200 cursor-not-allowed'
                        : 'text-gray-400 hover:text-gray-600'
                    }`}
                    title='Delete fact'
                    disabled={isEditingFact}
                  >
                    <DeleteIcon style={{ fontSize: '16px' }} />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default YourFacts;
