import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Spinner from './Spinner';

interface WordTranslationContentProps {
  wordTranslation: string;
  cleanedWord: string;
  isBookmarked: boolean;
  onBookmarkToggle: () => void;
  isFetchingBookmarkStatus: boolean;
}

const WordTranslationContent = ({
  wordTranslation,
  cleanedWord,
  isBookmarked,
  onBookmarkToggle,
  isFetchingBookmarkStatus,
}: WordTranslationContentProps) => (
  <>
    <div className='flex flex-col'>
      <p className='text-lg font-medium'>{cleanedWord}</p>
      <p className='text-sm text-gray-600'>{wordTranslation}</p>
    </div>
    <button onClick={onBookmarkToggle}>
      {isFetchingBookmarkStatus ? (
        <div className='pr-1'>
          <Spinner black />
        </div>
      ) : isBookmarked ? (
        <BookmarkIcon />
      ) : (
        <BookmarkBorderIcon />
      )}
    </button>
  </>
);

export default WordTranslationContent;
