import { Popover } from '@mui/material';
import classNames from 'classnames';
import Spinner from './Spinner';
import WordTranslationContent from './WordTranslationContent';

interface WordTranslationPopoverProps {
  id?: string;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  wordTranslation: string;
  cleanedWord: string;
  isBookmarked: boolean;
  onBookmarkToggle: () => void;
  isFetchingBookmarkStatus: boolean;
}

const WordTranslationPopover = ({
  id,
  open,
  anchorEl,
  onClose,
  wordTranslation,
  cleanedWord,
  isBookmarked,
  onBookmarkToggle,
  isFetchingBookmarkStatus,
}: WordTranslationPopoverProps) => (
  <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    disableScrollLock={true}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    sx={{
      '& .MuiPopover-paper': {
        borderRadius: '1rem',
      },
    }}
    keepMounted
  >
    <div
      className={classNames(
        'flex items-center p-3 min-w-[200px]',
        wordTranslation ? 'justify-between' : 'justify-center',
      )}
    >
      {wordTranslation ? (
        <WordTranslationContent
          {...{
            wordTranslation,
            cleanedWord,
            isBookmarked,
            onBookmarkToggle,
            isFetchingBookmarkStatus,
          }}
        />
      ) : (
        <Spinner />
      )}
    </div>
  </Popover>
);

export default WordTranslationPopover;
