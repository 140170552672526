import { ChangeEvent } from 'react';
import { LEVEL_DESCRIPTIONS } from '../utils/constants';

interface RadioButtonsProps {
  title: string;
  options: string[];
  name: string;
  selectedOption: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const getLevelDescription = (level: string) => {
  return LEVEL_DESCRIPTIONS[level as keyof typeof LEVEL_DESCRIPTIONS];
};

const RadioButtons = ({
  title,
  options,
  name,
  selectedOption,
  onChange,
}: RadioButtonsProps) => {
  return (
    <div className='rounded-lg bg-white px-3 py-1 shadow-md'>
      <p className='font-bold'>{title}</p>
      {options.map(value => (
        <div key={value}>
          <input
            type='radio'
            name={name}
            id={value}
            value={value}
            checked={selectedOption === value}
            onChange={onChange}
            className='mr-2'
          />
          <label htmlFor={value}>
            {name === 'level' ? getLevelDescription(value) : value}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioButtons;
