import { FormEvent, useState, useCallback } from 'react';
import {
  useUserContext,
  ICustomInstructions,
  UserFact,
} from '../contexts/UserContext';
import Takeover from './Takeover';
import toast from 'react-hot-toast';
import userAPI from '../api/user/userAPI';
import YourFacts from './YourFacts';

interface PersonalizationTakeoverProps {
  id: string;
  closePersonalizationTakeover: () => void;
}

const personalizationForm = 'personalizationForm';

const PersonalizationTakeover = ({
  id,
  closePersonalizationTakeover,
}: PersonalizationTakeoverProps) => {
  const { user, updateUser } = useUserContext();
  const [customInstructions, setCustomInstructions] =
    useState<ICustomInstructions>(user.customInstructions);
  const [userFacts, setUserFacts] = useState<UserFact[]>(user.userFacts);
  const [editingFactIndex, setEditingFactIndex] = useState<number | null>(null);
  const [editingFactContent, setEditingFactContent] = useState('');

  const getHasUnsavedChanges = useCallback(() => {
    const instructionsChanged =
      customInstructions.whatToKnowAboutUser !==
      user.customInstructions.whatToKnowAboutUser;

    const factsChanged =
      userFacts.length !== user.userFacts.length ||
      JSON.stringify(userFacts) !== JSON.stringify(user.userFacts);

    return instructionsChanged || factsChanged;
  }, [customInstructions, userFacts, user.customInstructions, user.userFacts]);

  const handleClose = () => {
    if (getHasUnsavedChanges()) {
      const confirmExit = window.confirm(
        'You have unsaved changes. Are you sure you want to exit without saving?',
      );

      if (!confirmExit) return;
    }

    closePersonalizationTakeover();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const updatedUser = await userAPI.updateUser({
        userEmail: user.email,
        customInstructions,
        userFacts,
      });

      updateUser(updatedUser);

      toast.success('Changes saved!');
    } catch (error) {
      toast.error('Error saving changes, please try again.');
    }

    closePersonalizationTakeover();
  };

  const handleEditFact = async (index: number) => {
    if (!editingFactContent.trim()) return;

    const updatedFacts = [...userFacts];
    updatedFacts[index] = {
      content: editingFactContent,
      addedBy: 'user',
      addedAt: new Date(),
    };

    setUserFacts(updatedFacts);
    setEditingFactIndex(null);
    setEditingFactContent('');
  };

  const handleDeleteFact = async (index: number) => {
    const updatedFacts = [...userFacts];
    updatedFacts.splice(index, 1);
    setUserFacts(updatedFacts);
  };

  return (
    <Takeover
      handleCloseScreenTakeover={handleClose}
      title={'Personalization'}
      id={id}
    >
      <>
        <form
          id={personalizationForm}
          onSubmit={handleSubmit}
          className='flex flex-col gap-y-6'
        >
          <CustomInstructions
            {...{
              customInstructions,
              setCustomInstructions,
            }}
          />
          <YourFacts
            {...{
              editingFactIndex,
              setEditingFactIndex,
              editingFactContent,
              setEditingFactContent,
              handleEditFact,
              handleDeleteFact,
              userFacts,
            }}
          />
        </form>
        <CancelAndSaveButtons
          handleClose={handleClose}
          getHasUnsavedChanges={getHasUnsavedChanges}
        />
      </>
    </Takeover>
  );
};

const CustomInstructions = ({
  customInstructions,
  setCustomInstructions,
}: {
  customInstructions: ICustomInstructions;
  setCustomInstructions: (customInstructions: ICustomInstructions) => void;
}) => (
  <div className='space-y-2'>
    <label
      htmlFor='customInstructions'
      className='text-lg font-semibold text-gray-800'
    >
      About you
    </label>

    <div className='text-sm text-gray-600 space-y-1'>
      <p>What would you like tutors to know about you?</p>
      <p>This will help personalize your lessons.</p>
    </div>
    <textarea
      id='customInstructions'
      value={customInstructions.whatToKnowAboutUser}
      onChange={e =>
        setCustomInstructions({
          ...customInstructions,
          whatToKnowAboutUser: e.target.value,
        })
      }
      placeholder="Example: I'm learning this language for business travel. I struggle with verb conjugations."
      rows={4}
      className='w-full px-4 py-3 rounded-lg border border-gray-300 resize-none shadow-sm'
    />
  </div>
);

const CancelAndSaveButtons = ({
  handleClose,
  getHasUnsavedChanges,
}: {
  handleClose: () => void;
  getHasUnsavedChanges: () => boolean;
}) => (
  <div className='absolute bottom-5 right-5 font-medium'>
    <button
      type='button'
      onClick={handleClose}
      className='mr-3 p-3 text-gray-700 bg-gray-300 rounded-lg shadow-md hover:bg-gray-400'
    >
      Cancel
    </button>
    <button
      type='submit'
      form={personalizationForm}
      disabled={!getHasUnsavedChanges()}
      className={`p-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 ${
        !getHasUnsavedChanges() ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      Save
    </button>
  </div>
);

export default PersonalizationTakeover;
