import { SwipeableDrawer, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PublicIcon from '@mui/icons-material/Public';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import classNames from 'classnames';

interface TopicSuggestionsProps {
  closeTopicSuggestions: () => void;
  handleTopicClick: (text: string) => void;
  isDisabled: boolean;
  open: boolean;
}

enum TopicType {
  Discuss = 'discuss',
  Learn = 'learn',
  Culture = 'culture',
}

interface TopicItem {
  topic: string;
  type: TopicType;
  emoji?: string;
}

interface TopicCategory {
  name: string;
  icon: React.ReactNode;
  topics: TopicItem[];
}

// Organize topics into categories
const TOPIC_CATEGORIES: TopicCategory[] = [
  {
    name: 'Daily Life',
    icon: <ChatIcon />,
    topics: [
      { topic: 'My day', type: TopicType.Discuss, emoji: '📅' },
      {
        topic: 'Yesterday',
        type: TopicType.Discuss,
        emoji: '⏪',
      },
      { topic: 'Tomorrow', type: TopicType.Discuss, emoji: '⏩' },
      { topic: 'Weekend plans', type: TopicType.Discuss, emoji: '🏖️' },
      { topic: 'My hobbies', type: TopicType.Discuss, emoji: '🌟' },
    ],
  },
  {
    name: "Tutor's Country",
    icon: <PublicIcon />,
    topics: [
      { topic: 'Culture', type: TopicType.Culture, emoji: '🏛️' },
      { topic: 'Customs', type: TopicType.Culture, emoji: '🎭' },
      { topic: 'Mentality', type: TopicType.Culture, emoji: '🧠' },
      { topic: 'History', type: TopicType.Culture, emoji: '📜' },
      { topic: 'Traditions', type: TopicType.Culture, emoji: '🎎' },
      { topic: 'Geography', type: TopicType.Culture, emoji: '🗺️' },
      { topic: 'Languages', type: TopicType.Culture, emoji: '🗣️' },
      { topic: 'Food', type: TopicType.Culture, emoji: '🍽️' },
    ],
  },
  {
    name: 'Fun & Entertainment',
    icon: <EmojiEmotionsIcon />,
    topics: [
      { topic: 'Movies', type: TopicType.Discuss, emoji: '🎬' },
      { topic: 'TV shows', type: TopicType.Discuss, emoji: '📺' },
      { topic: 'Music', type: TopicType.Discuss, emoji: '🎵' },
      { topic: 'Books', type: TopicType.Discuss, emoji: '📚' },
      { topic: 'Video games', type: TopicType.Discuss, emoji: '🎮' },
      { topic: 'Art', type: TopicType.Discuss, emoji: '🎨' },
    ],
  },
  {
    name: 'Hobbies',
    icon: <LocalActivityIcon />,
    topics: [
      { topic: 'Cooking', type: TopicType.Discuss, emoji: '👨‍🍳' },
      { topic: 'Exercise', type: TopicType.Discuss, emoji: '💪' },
      { topic: 'Travel', type: TopicType.Discuss, emoji: '✈️' },
      { topic: 'Photography', type: TopicType.Discuss, emoji: '📷' },
      { topic: 'Gardening', type: TopicType.Discuss, emoji: '🌱' },
      { topic: 'DIY', type: TopicType.Discuss, emoji: '🔨' },
      { topic: 'Hiking', type: TopicType.Discuss, emoji: '🥾' },
      { topic: 'Camping', type: TopicType.Discuss, emoji: '⛺' },
    ],
  },

  {
    name: 'Learning',
    icon: <MenuBookIcon />,
    topics: [
      { topic: 'Science', type: TopicType.Discuss, emoji: '🔬' },
      { topic: 'Technology', type: TopicType.Discuss, emoji: '💻' },
      { topic: 'Philosophy', type: TopicType.Discuss, emoji: '🤔' },
      { topic: 'Psychology', type: TopicType.Discuss, emoji: '🧠' },
    ],
  },
  {
    name: 'Sports',
    icon: <SportsBasketballIcon />,
    topics: [
      { topic: 'Football (Soccer)', type: TopicType.Discuss, emoji: '⚽' },
      { topic: 'Basketball', type: TopicType.Discuss, emoji: '🏀' },
      { topic: 'Baseball', type: TopicType.Discuss, emoji: '⚾' },
      { topic: 'American Football', type: TopicType.Discuss, emoji: '🏈' },
      { topic: 'Hockey', type: TopicType.Discuss, emoji: '🏒' },
      { topic: 'Martial arts', type: TopicType.Discuss, emoji: '🥋' },
      { topic: 'Boxing', type: TopicType.Discuss, emoji: '🥊' },
      { topic: 'Tennis', type: TopicType.Discuss, emoji: '🎾' },
      { topic: 'Running', type: TopicType.Discuss, emoji: '🏃' },
      { topic: 'Golf', type: TopicType.Discuss, emoji: '⛳' },
      { topic: 'Yoga', type: TopicType.Discuss, emoji: '🧘' },
      { topic: 'Volleyball', type: TopicType.Discuss, emoji: '🏐' },
      { topic: 'Swimming', type: TopicType.Discuss, emoji: '🏊' },
      { topic: 'Cycling', type: TopicType.Discuss, emoji: '🚴' },
      { topic: 'Cricket', type: TopicType.Discuss, emoji: '🏏' },
    ],
  },
];

const TopicSuggestions = ({
  closeTopicSuggestions,
  handleTopicClick,
  isDisabled,
  open,
}: TopicSuggestionsProps) => {
  const theme = useTheme();

  // Function to format topic text for prompting conversation
  const formatTopicForConversation = (topicItem: TopicItem) => {
    switch (topicItem.type) {
      case TopicType.Discuss:
        return `Let's talk about ${topicItem.topic.toLowerCase()}`;
      case TopicType.Learn:
        return `Teach me vocabulary and phrases about ${topicItem.topic.toLowerCase()}`;
      case TopicType.Culture:
        return `Let's talk about your country's ${topicItem.topic.toLowerCase()}`;
    }
  };

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={open}
      onClose={closeTopicSuggestions}
      onOpen={() => {}}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '70vh',
          padding: theme.spacing(2),
          backgroundColor: '#f3f4f6',
        },
      }}
    >
      <div className='flex justify-between items-center mb-4'>
        <h2 className='font-bold text-xl'>Topic Suggestions</h2>
        <button
          onClick={closeTopicSuggestions}
          className='p-4 rounded-full hover:bg-gray-200'
        >
          <CloseIcon />
        </button>
      </div>

      <div className='pb-8 px-2 overflow-y-auto'>
        {TOPIC_CATEGORIES.map(category => (
          <div key={category.name} className='mb-6'>
            <div className='flex items-center mb-2'>
              <span className='mr-2 flex items-center'>{category.icon}</span>
              <h3 className='font-bold text-lg'>{category.name}</h3>
            </div>

            <div className='grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 gap-2'>
              {category.topics.map(topicItem => (
                <div key={topicItem.topic}>
                  <button
                    disabled={isDisabled}
                    className={classNames(
                      'w-full p-3 rounded-lg border shadow-sm hover:shadow-lg bg-white',
                      isDisabled && 'opacity-50 cursor-not-allowed',
                    )}
                    onClick={() =>
                      handleTopicClick(formatTopicForConversation(topicItem))
                    }
                  >
                    <p className='text-center text-sm font-bold text-black whitespace-nowrap overflow-hidden text-ellipsis'>
                      {topicItem.emoji && (
                        <span className='mr-1'>{topicItem.emoji}</span>
                      )}
                      {topicItem.topic}
                    </p>
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </SwipeableDrawer>
  );
};

export default TopicSuggestions;
