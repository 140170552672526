'use client';

import type React from 'react';

import { useState, useRef, useEffect, useCallback } from 'react';
import {
  ChevronDown,
  ChevronUp,
  Expand,
  Eye,
  EyeOff,
  MessageSquare,
  WalletCardsIcon as Cards,
  Trash2,
  RefreshCw,
  Sparkles,
  Edit,
  Check,
  X,
  ChevronLeft,
  ChevronRight,
  ArrowLeft,
  ThumbsUp,
  ThumbsDown,
} from 'lucide-react';
import { Button } from '../components/ui/button';
import { Switch } from '../components/ui/switch';
import { Checkbox } from '../components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from '../components/ui/dialog';
import { Textarea } from '../components/ui/textarea';
import { Progress } from '../components/ui/progress';
import { cn } from '../lib/utils';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../components/ui/alert-dialog';
import flashCardsAPI from '../api/flashCardsAPI';
import { useUserContext } from '../contexts/UserContext';
import {
  FlashCard,
  FlashCardDifficulty,
  useFlashCardsContext,
} from '../contexts/FlashCardsContext';
import { toast } from 'react-hot-toast';

interface DateGroup {
  date: string;
  words: FlashCard[];
}

export default function ReviewPage() {
  const { flashCards, setFlashCards } = useFlashCardsContext();
  const [showTranslation, setShowTranslation] = useState(true);
  const [selectedWords, setSelectedWords] = useState<Set<string>>(new Set());
  const [expandedWord, setExpandedWord] = useState<FlashCard | null>(null);
  const [dateGroupExpanded, setDateGroupExpanded] = useState<Set<string>>(
    new Set(),
  );
  const [wordData, setWordData] = useState<DateGroup[]>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [wordToDelete, setWordToDelete] = useState<{
    wordId: string;
    dateIndex: number;
  } | null>(null);
  const [isEditingMnemonic, setIsEditingMnemonic] = useState(false);
  const [editedMnemonic, setEditedMnemonic] = useState('');
  const [isGeneratingMnemonic, setIsGeneratingMnemonic] = useState(false);
  const [isGeneratingExample, setIsGeneratingExample] = useState(false);
  const { _id: userId, nativeLanguage } = useUserContext().user;

  // FlashCard review state
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [reviewWords, setReviewWords] = useState<FlashCard[]>([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isCardFlipped, setIsCardFlipped] = useState(false);

  // Group flashCards by date when flashCards change
  useEffect(() => {
    const groupedByDate = flashCards.reduce(
      (acc: Record<string, FlashCard[]>, flashCard) => {
        const date = new Date(flashCard.lastReviewed).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(flashCard);
        return acc;
      },
      {},
    );

    const dateGroups = Object.entries(groupedByDate).map(([date, words]) => ({
      date,
      words,
    }));

    // Sort by date descending
    dateGroups.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );

    setWordData(dateGroups);
    setDateGroupExpanded(new Set(dateGroups.map(group => group.date)));
  }, [flashCards]);

  // Start flashCard review
  const startFlashCardReview = () => {
    let wordsToReview: FlashCard[] = [];

    // If words are selected, use those
    if (selectedWords.size > 0) {
      // Flatten all words and filter by selected IDs
      const allWords = wordData.flatMap(group => group.words);
      wordsToReview = allWords.filter(word => selectedWords.has(word._id));
    } else {
      // Otherwise use all words
      wordsToReview = wordData.flatMap(group => group.words);
    }

    // Shuffle the words for review
    const shuffled = [...wordsToReview].sort(() => Math.random() - 0.5);

    setReviewWords(shuffled);
    setCurrentCardIndex(0);
    setIsCardFlipped(false);
    setIsReviewMode(true);
  };

  // End flashCard review
  const endFlashCardReview = useCallback(() => {
    setIsReviewMode(false);
    setReviewWords([]);
    setCurrentCardIndex(0);
  }, []);
  // Navigate to next card
  const nextCard = useCallback(() => {
    if (currentCardIndex < reviewWords.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setIsCardFlipped(false);
    } else {
      // End of review
      endFlashCardReview();
    }
  }, [currentCardIndex, reviewWords.length, endFlashCardReview]);
  // Navigate to previous card
  const prevCard = useCallback(() => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
      setIsCardFlipped(false);
    }
  }, [currentCardIndex]);
  // Mark current card status
  const markCardStatus = useCallback(
    (difficulty: FlashCardDifficulty) => {
      if (reviewWords.length === 0) return;

      const updatedReviewWords = [...reviewWords];
      updatedReviewWords[currentCardIndex] = {
        ...updatedReviewWords[currentCardIndex],
        difficulty,
      };
      setReviewWords(updatedReviewWords);

      // Also update in the main word data
      const wordId = reviewWords[currentCardIndex]._id;
      const newWordData = [...wordData];

      for (let i = 0; i < newWordData.length; i++) {
        const wordIndex = newWordData[i].words.findIndex(w => w._id === wordId);
        if (wordIndex !== -1) {
          newWordData[i].words[wordIndex] = {
            ...newWordData[i].words[wordIndex],
            difficulty,
          };
          break;
        }
      }

      setWordData(newWordData);

      // Move to next card
      nextCard();
    },
    [reviewWords, currentCardIndex, wordData, setWordData, nextCard],
  );

  // Toggle date group expansion
  const toggleDateGroup = (date: string) => {
    const newExpanded = new Set(dateGroupExpanded);
    if (newExpanded.has(date)) {
      newExpanded.delete(date);
    } else {
      newExpanded.add(date);
    }
    setDateGroupExpanded(newExpanded);
  };

  // Toggle word selection
  const toggleWordSelection = (wordId: string) => {
    const newSelected = new Set(selectedWords);
    if (newSelected.has(wordId)) {
      newSelected.delete(wordId);
    } else {
      newSelected.add(wordId);
    }
    setSelectedWords(newSelected);
  };

  // Toggle date group selection
  const toggleDateGroupSelection = (date: string, words: FlashCard[]) => {
    const newSelected = new Set(selectedWords);
    const allSelected = words.every(word => selectedWords.has(word._id));

    if (allSelected) {
      // Unselect all words in this date group
      words.forEach(word => {
        newSelected.delete(word._id);
      });
    } else {
      // Select all words in this date group
      words.forEach(word => {
        newSelected.add(word._id);
      });
    }
    setSelectedWords(newSelected);
  };

  // Delete a word
  const deleteWord = (wordId: string, dateIndex: number) => {
    setWordToDelete({ wordId, dateIndex });
    setDeleteConfirmOpen(true);
  };

  // Confirm word deletion
  const confirmDeleteWord = async () => {
    if (!wordToDelete) return;

    const { wordId, dateIndex } = wordToDelete;
    const newWordData = [...wordData];

    await flashCardsAPI.deleteFlashCards(userId, [wordId]);

    // Remove the word from the date group
    newWordData[dateIndex].words = newWordData[dateIndex].words.filter(
      word => word._id !== wordId,
    );

    // If the date group is now empty, remove it
    if (newWordData[dateIndex].words.length === 0) {
      newWordData.splice(dateIndex, 1);
    }

    // Remove from selected words if it was selected
    if (selectedWords.has(wordId)) {
      const newSelected = new Set(selectedWords);
      newSelected.delete(wordId);
      setSelectedWords(newSelected);
    }

    // Close the expanded view if this word was expanded
    if (expandedWord && expandedWord._id === wordId) {
      setExpandedWord(null);
    }

    setWordData(newWordData);
    setDeleteConfirmOpen(false);
    setWordToDelete(null);
  };

  // Get a new example sentence
  const getExampleSentence = async (wordId: string) => {
    if (!expandedWord || expandedWord._id !== wordId) return;

    try {
      setIsGeneratingExample(true);
      const updatedFlashCard = await flashCardsAPI.createExampleSentence(
        wordId,
        expandedWord.word,
        expandedWord.exampleSentence,
      );

      setFlashCards(
        flashCards.map(flashCard =>
          flashCard._id === wordId ? updatedFlashCard : flashCard,
        ),
      );

      setExpandedWord(updatedFlashCard);
    } catch (error) {
      console.error('Error generating example sentence', error);
      toast.error('Error generating example sentence. Please try again.');
    } finally {
      setIsGeneratingExample(false);
    }
  };

  const generateMnemonic = async (wordId: string) => {
    try {
      setIsGeneratingMnemonic(true);

      const updatedFlashCard = await flashCardsAPI.createMnemonic(
        wordId,
        expandedWord?.word || '',
        expandedWord?.mnemonic || '',
        nativeLanguage,
      );

      setFlashCards(
        flashCards.map(flashCard =>
          flashCard._id === wordId ? updatedFlashCard : flashCard,
        ),
      );

      setExpandedWord(updatedFlashCard);
    } catch (error) {
      console.error('Error generating mnemonic', error);
      toast.error('Error generating mnemonic. Please try again.');
    } finally {
      setIsGeneratingMnemonic(false);
    }
  };

  const startEditMnemonic = () => {
    if (!expandedWord) return;
    setEditedMnemonic(expandedWord.mnemonic || '');
    setIsEditingMnemonic(true);
  };

  const saveEditedMnemonic = async () => {
    if (!expandedWord) return;

    try {
      const updatedFlashCard = await flashCardsAPI.updateFlashCard(
        expandedWord._id,
        { ...expandedWord, mnemonic: editedMnemonic.trim() },
      );

      setFlashCards(
        flashCards.map(flashCard =>
          flashCard._id === expandedWord._id ? updatedFlashCard : flashCard,
        ),
      );

      setExpandedWord(updatedFlashCard);
    } catch (error) {
      console.error('Error updating mnemonic', error);
      toast.error('Error updating mnemonic. Please try again.');
    } finally {
      setIsEditingMnemonic(false);
    }
  };

  const cancelEditMnemonic = () => {
    setIsEditingMnemonic(false);
  };

  // Handle keyboard navigation in review mode
  useEffect(() => {
    if (!isReviewMode) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case ' ':
        case 'Enter':
          setIsCardFlipped(!isCardFlipped);
          break;
        case 'ArrowRight':
        case 'n':
          nextCard();
          break;
        case 'ArrowLeft':
        case 'p':
          prevCard();
          break;
        case 'Escape':
          endFlashCardReview();
          break;
        case '1':
          markCardStatus(FlashCardDifficulty.EASY);
          break;
        case '2':
          markCardStatus(FlashCardDifficulty.MEDIUM);
          break;
        case '3':
          markCardStatus(FlashCardDifficulty.HARD);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [
    isReviewMode,
    isCardFlipped,
    currentCardIndex,
    nextCard,
    prevCard,
    markCardStatus,
    endFlashCardReview,
  ]);

  // If in review mode, show the flashCard review UI
  if (isReviewMode) {
    const currentWord = reviewWords[currentCardIndex];
    const progress = ((currentCardIndex + 1) / reviewWords.length) * 100;

    return (
      <div className='fixed inset-0 bg-background z-50 flex flex-col'>
        <header className='border-b p-4 flex items-center justify-between'>
          <Button
            variant='ghost'
            onClick={endFlashCardReview}
            className='gap-1'
          >
            <ArrowLeft className='h-4 w-4' />
            Back to Review
          </Button>
          <div className='text-sm'>
            Card {currentCardIndex + 1} of {reviewWords.length}
          </div>
        </header>

        <div className='flex-1 flex flex-col items-center justify-center p-4'>
          <div
            className='w-full max-w-md h-[400px] cursor-pointer'
            onClick={() => setIsCardFlipped(!isCardFlipped)}
          >
            <div className='relative h-full w-full'>
              {/* Front of card */}
              <div
                className={cn(
                  'absolute inset-0 flex flex-col items-center justify-center p-6 border rounded-lg shadow-lg transition-all duration-500',
                  isCardFlipped
                    ? 'opacity-0 rotate-y-90'
                    : 'opacity-100 rotate-y-0',
                )}
              >
                <img
                  src={currentWord.imageUrl || '/placeholder.svg'}
                  alt=''
                  className='h-40 w-40 rounded-lg object-cover mb-6'
                  aria-hidden='true'
                />
                <h2 className='text-3xl font-bold text-center mb-2'>
                  {currentWord.word}
                </h2>
                <p className='text-sm text-center mt-6 text-muted-foreground'>
                  Click to flip
                </p>
              </div>

              {/* Back of card */}
              <div
                className={cn(
                  'absolute inset-0 flex flex-col items-center justify-center p-6 border rounded-lg shadow-lg transition-all duration-500',
                  isCardFlipped
                    ? 'opacity-100 rotate-y-0'
                    : 'opacity-0 rotate-y-90',
                )}
              >
                <img
                  src={currentWord.imageUrl || '/placeholder.svg'}
                  alt=''
                  className='h-32 w-32 rounded-lg object-cover mb-4'
                  aria-hidden='true'
                />
                <h2 className='text-2xl font-bold text-center mb-1'>
                  {currentWord.word}
                </h2>
                {showTranslation && (
                  <p className='text-xl text-primary text-center mb-4'>
                    {currentWord.translation}
                  </p>
                )}
                <p className='text-base text-center mb-4 italic'>
                  "{currentWord.exampleSentence}"
                </p>
                {currentWord.mnemonic && (
                  <div className='bg-muted p-3 rounded-lg w-full max-w-sm'>
                    <p className='text-sm'>{currentWord.mnemonic}</p>
                  </div>
                )}
                <p className='text-sm text-center mt-4 text-muted-foreground'>
                  Click to flip
                </p>
              </div>
            </div>
          </div>

          <div className='mt-8 w-full max-w-md'>
            <div className='flex justify-between items-center mb-2'>
              <span className='text-sm text-muted-foreground'>Progress</span>
              <span className='text-sm font-medium'>
                {Math.round(progress)}%
              </span>
            </div>
            <Progress value={progress} className='h-2' />
          </div>
        </div>

        <footer className='border-t p-4'>
          <div className='flex justify-between items-center max-w-md mx-auto'>
            <Button
              variant='outline'
              onClick={prevCard}
              disabled={currentCardIndex === 0}
              className='w-12 h-12 rounded-full p-0 flex items-center justify-center'
            >
              <ChevronLeft className='h-6 w-6' />
            </Button>

            <div className='flex gap-2'>
              <Button
                variant='outline'
                onClick={() => markCardStatus(FlashCardDifficulty.HARD)}
                className='flex flex-col items-center px-4 py-2 h-auto'
              >
                <ThumbsDown className='h-5 w-5 text-destructive mb-1' />
                <span className='text-xs'>Difficult</span>
              </Button>

              <Button
                variant='outline'
                onClick={() => markCardStatus(FlashCardDifficulty.MEDIUM)}
                className='flex flex-col items-center px-4 py-2 h-auto'
              >
                <RefreshCw className='h-5 w-5 text-amber-500 mb-1' />
                <span className='text-xs'>Learning</span>
              </Button>

              <Button
                variant='outline'
                onClick={() => markCardStatus(FlashCardDifficulty.EASY)}
                className='flex flex-col items-center px-4 py-2 h-auto'
              >
                <ThumbsUp className='h-5 w-5 text-green-500 mb-1' />
                <span className='text-xs'>Known</span>
              </Button>
            </div>

            <Button
              variant='outline'
              onClick={nextCard}
              disabled={currentCardIndex === reviewWords.length - 1}
              className='w-12 h-12 rounded-full p-0 flex items-center justify-center'
            >
              <ChevronRight className='h-6 w-6' />
            </Button>
          </div>
        </footer>
      </div>
    );
  }

  return (
    <div className='container max-w-4xl mx-auto px-4 py-6'>
      <header className='sticky top-0 z-10 bg-background pb-4 border-b'>
        <h1 className='text-2xl font-bold mb-4'>Review</h1>

        <div className='flex flex-col sm:flex-row justify-between gap-4 mb-4'>
          <div className='flex gap-2'>
            <Button
              variant='default'
              className='flex-1 sm:flex-none'
              onClick={() =>
                console.log('Review with tutor', Array.from(selectedWords))
              }
            >
              <MessageSquare className='mr-2 h-4 w-4' />
              {selectedWords.size > 0
                ? 'Review selected w/ tutor'
                : 'Review with tutor'}
            </Button>
            <Button
              variant='outline'
              className='flex-1 sm:flex-none'
              onClick={startFlashCardReview}
            >
              <Cards className='mr-2 h-4 w-4' />
              {selectedWords.size > 0
                ? 'Review selected flashCards'
                : 'Review flashCards'}
            </Button>
          </div>

          <div className='flex items-center gap-2'>
            <span className='text-sm'>Show translations</span>
            <Switch
              checked={showTranslation}
              onCheckedChange={setShowTranslation}
              aria-label='Toggle translation visibility'
            />
            {showTranslation ? (
              <Eye className='h-4 w-4' />
            ) : (
              <EyeOff className='h-4 w-4' />
            )}
          </div>
        </div>

        {selectedWords.size > 0 && (
          <div className='text-sm text-muted-foreground mb-2'>
            {selectedWords.size} word{selectedWords.size !== 1 ? 's' : ''}{' '}
            selected
          </div>
        )}
      </header>

      <main className='mt-4'>
        {wordData.length === 0 ? (
          <div className='text-center py-8 text-muted-foreground'>
            <p>No words to review. Add some words to get started!</p>
          </div>
        ) : (
          wordData.map((group, dateIndex) => (
            <div key={group.date} className='mb-6'>
              <div
                className='flex items-center gap-2 mb-2 cursor-pointer'
                onClick={() => toggleDateGroup(group.date)}
              >
                <DateGroupCheckbox
                  words={group.words}
                  selectedWords={selectedWords}
                  onToggleSelection={() =>
                    toggleDateGroupSelection(group.date, group.words)
                  }
                  onClick={e => e.stopPropagation()}
                  aria-label={`Select all words from ${group.date}`}
                />
                <h2 className='text-lg font-semibold flex-1'>{group.date}</h2>
                {dateGroupExpanded?.has(group.date) ? (
                  <ChevronUp className='h-5 w-5' />
                ) : (
                  <ChevronDown className='h-5 w-5' />
                )}
              </div>

              {dateGroupExpanded?.has(group.date) && (
                <div className='space-y-3 pl-6'>
                  {group.words.map(word => (
                    <FlashCardComponent
                      key={word._id}
                      word={word}
                      showTranslation={showTranslation}
                      isSelected={selectedWords.has(word._id)}
                      onToggleSelect={() => toggleWordSelection(word._id)}
                      onExpand={() => setExpandedWord(word)}
                      onDelete={() => deleteWord(word._id, dateIndex)}
                    />
                  ))}
                </div>
              )}
            </div>
          ))
        )}
      </main>

      <Dialog
        open={expandedWord !== null}
        onOpenChange={open => !open && setExpandedWord(null)}
      >
        <DialogContent className='sm:max-w-md'>
          {expandedWord && (
            <>
              <DialogTitle>Word Details</DialogTitle>
              <DialogDescription>
                View and edit details for {expandedWord.word}
              </DialogDescription>
              <ExpandedCardView
                word={expandedWord}
                onRefreshExample={() => getExampleSentence(expandedWord._id)}
                onGenerateMnemonic={() => generateMnemonic(expandedWord._id)}
                onEditMnemonic={startEditMnemonic}
                isGeneratingMnemonic={isGeneratingMnemonic}
                isGeneratingExample={isGeneratingExample}
                isEditingMnemonic={isEditingMnemonic}
                editedMnemonic={editedMnemonic}
                onEditedMnemonicChange={setEditedMnemonic}
                onSaveMnemonic={saveEditedMnemonic}
                onCancelMnemonic={cancelEditMnemonic}
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      <AlertDialog open={deleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Word</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this word? This action cannot be
              undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={confirmDeleteWord}
              className='bg-destructive text-destructive-foreground'
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

// DateGroupCheckbox Component
interface DateGroupCheckboxProps {
  words: FlashCard[];
  selectedWords: Set<string>;
  onToggleSelection: () => void;
  onClick?: (e: React.MouseEvent) => void;
  'aria-label'?: string;
}

function DateGroupCheckbox({
  words,
  selectedWords,
  onToggleSelection,
  onClick,
  ...props
}: DateGroupCheckboxProps) {
  const checkboxRef = useRef<HTMLButtonElement>(null);

  const isAllSelected = words.every(word => selectedWords.has(word._id));
  const isPartiallySelected =
    words.some(word => selectedWords.has(word._id)) && !isAllSelected;

  useEffect(() => {
    if (checkboxRef.current) {
      // Cast to HTMLInputElement since Checkbox uses input underneath
      (checkboxRef.current as unknown as HTMLInputElement).indeterminate =
        isPartiallySelected;
    }
  }, [isPartiallySelected]);

  return (
    <Checkbox
      ref={checkboxRef}
      checked={isAllSelected}
      onCheckedChange={onToggleSelection}
      onClick={onClick}
      {...props}
    />
  );
}

// FlashCard Component
interface FlashCardProps {
  word: FlashCard;
  showTranslation: boolean;
  isSelected: boolean;
  onToggleSelect: () => void;
  onExpand: () => void;
  onDelete: () => void;
}

function FlashCardComponent({
  word,
  showTranslation,
  isSelected,
  onToggleSelect,
  onExpand,
  onDelete,
}: FlashCardProps) {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      className={cn(
        'border rounded-lg overflow-hidden transition-all',
        isSelected ? 'border-primary' : 'border-border',
      )}
    >
      <div className='flex items-start p-3'>
        <Checkbox
          checked={isSelected}
          onCheckedChange={() => onToggleSelect()}
          className='mt-1 mr-3'
          aria-label={`Select ${word.word}`}
        />

        {showTranslation ? (
          // Non-flippable view when translations are shown
          <div className='flex-1'>
            <div className='flex items-start'>
              <div className='flex-1'>
                <h3 className='font-semibold text-lg'>{word.word}</h3>
                <p className='text-muted-foreground'>{word.translation}</p>
              </div>
              <img
                src={word.imageUrl || '/placeholder.svg'}
                alt=''
                className='h-16 w-16 rounded object-cover'
                aria-hidden='true'
              />
            </div>
          </div>
        ) : (
          // Flippable view when translations are hidden
          <div
            className='flex-1 cursor-pointer perspective-1000'
            onClick={() => setIsFlipped(!isFlipped)}
          >
            <div
              className={cn(
                'relative w-full transition-transform duration-300 transform-style-preserve-3d',
                isFlipped ? 'rotate-y-180' : '',
              )}
            >
              {/* Front of card */}
              <div
                className={cn(
                  'flex items-start backface-hidden',
                  isFlipped ? 'invisible' : 'visible',
                )}
              >
                <div className='flex-1'>
                  <h3 className='font-semibold text-lg'>{word.word}</h3>
                </div>
                <img
                  src={word.imageUrl || '/placeholder.svg'}
                  alt=''
                  className='h-16 w-16 rounded object-cover'
                  aria-hidden='true'
                />
              </div>

              {/* Back of card */}
              <div
                className={cn(
                  'absolute inset-0 backface-hidden rotate-y-180',
                  isFlipped ? 'visible' : 'invisible',
                )}
              >
                <div className='flex items-start'>
                  <div className='flex-1'>
                    <h3 className='font-semibold text-lg'>{word.word}</h3>
                    <p className='text-primary'>{word.translation}</p>
                  </div>
                  <img
                    src={word.imageUrl || '/placeholder.svg'}
                    alt=''
                    className='h-16 w-16 rounded object-cover'
                    aria-hidden='true'
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='flex flex-col gap-1 ml-2'>
          <button
            onClick={e => {
              e.stopPropagation();
              onExpand();
            }}
            className='p-1 text-muted-foreground hover:text-foreground'
            aria-label='Expand card'
          >
            <Expand className='h-5 w-5' />
          </button>

          <button
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
            className='p-1 text-muted-foreground hover:text-destructive'
            aria-label='Delete card'
          >
            <Trash2 className='h-5 w-5' />
          </button>
        </div>
      </div>
    </div>
  );
}

interface ExpandedCardViewProps {
  word: FlashCard;
  onRefreshExample: () => void;
  onGenerateMnemonic: () => void;
  onEditMnemonic: () => void;
  isGeneratingMnemonic: boolean;
  isGeneratingExample: boolean;
  isEditingMnemonic: boolean;
  editedMnemonic: string;
  onEditedMnemonicChange: (value: string) => void;
  onSaveMnemonic: () => void;
  onCancelMnemonic: () => void;
}

function ExpandedCardView({
  word,
  onRefreshExample,
  onGenerateMnemonic,
  onEditMnemonic,
  isGeneratingMnemonic,
  isGeneratingExample,
  isEditingMnemonic,
  editedMnemonic,
  onEditedMnemonicChange,
  onSaveMnemonic,
  onCancelMnemonic,
}: ExpandedCardViewProps) {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div className='p-2'>
      <div className='mb-4 h-[300px] w-full'>
        <div
          className='relative h-full w-full cursor-pointer'
          onClick={() => setIsFlipped(!isFlipped)}
        >
          {/* Card container with 3D effect */}
          <div className='relative h-full w-full'>
            {/* Front of card */}
            <div
              className={cn(
                'absolute inset-0 flex flex-col items-center justify-center p-4 border rounded-lg transition-all duration-500',
                isFlipped ? 'opacity-0 rotate-y-90' : 'opacity-100 rotate-y-0',
              )}
            >
              <img
                src={word.imageUrl || '/placeholder.svg'}
                alt=''
                className='h-32 w-32 rounded-lg object-cover mb-4'
                aria-hidden='true'
              />
              <h2 className='text-2xl font-bold text-center'>{word.word}</h2>
              <p className='text-sm text-center mt-4 text-muted-foreground'>
                Click to flip
              </p>
            </div>

            {/* Back of card */}
            <div
              className={cn(
                'absolute inset-0 flex flex-col items-center justify-center p-4 border rounded-lg transition-all duration-500',
                isFlipped ? 'opacity-100 rotate-y-0' : 'opacity-0 rotate-y-90',
              )}
            >
              <img
                src={word.imageUrl || '/placeholder.svg'}
                alt=''
                className='h-32 w-32 rounded-lg object-cover mb-4'
                aria-hidden='true'
              />
              <p className='text-xl text-primary text-center mt-1'>
                {word.translation}
              </p>
              <p className='text-sm text-center mt-4 text-muted-foreground'>
                Click to flip
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <div className='flex items-center justify-between mb-2'>
          <h3 className='font-medium'>Example Sentence</h3>
          <Button
            variant='ghost'
            size='sm'
            onClick={e => {
              e.preventDefault();
              onRefreshExample();
            }}
            className='h-8 px-2'
          >
            <RefreshCw className='h-4 w-4 mr-1' />
            New Example
          </Button>
        </div>
        {isGeneratingExample ? (
          <div className='p-3 bg-muted rounded-lg flex items-center'>
            <div className='animate-spin mr-2'>
              <RefreshCw className='h-4 w-4' />
            </div>
            <span>Generating example sentence...</span>
          </div>
        ) : word.exampleSentence ? (
          <p className='p-3 bg-muted rounded-lg'>{word.exampleSentence}</p>
        ) : (
          <div className='p-3 bg-muted rounded-lg'>
            <p className='text-sm text-muted-foreground'>
              No example sentence yet. Click "New Example" to generate one.
            </p>
          </div>
        )}
      </div>

      <div className='mt-4'>
        <div className='flex items-center justify-between mb-2'>
          <h3 className='font-medium'>Mnemonic</h3>
          {!isEditingMnemonic && (
            <div className='flex gap-1'>
              <Button
                variant='ghost'
                size='sm'
                onClick={onEditMnemonic}
                className='h-8 px-2'
              >
                <Edit className='h-4 w-4 mr-1' />
                Edit
              </Button>
              <Button
                variant='ghost'
                size='sm'
                onClick={onGenerateMnemonic}
                disabled={isGeneratingMnemonic}
                className='h-8 px-2'
              >
                <Sparkles className='h-4 w-4 mr-1' />
                {word.mnemonic ? 'Regenerate' : 'Generate'}
              </Button>
            </div>
          )}
        </div>

        {isEditingMnemonic ? (
          <div className='space-y-2'>
            <Textarea
              value={editedMnemonic}
              onChange={e => onEditedMnemonicChange(e.target.value)}
              placeholder='Enter a mnemonic to help you remember this word...'
              className='min-h-[100px]'
            />
            <div className='flex justify-end gap-2'>
              <Button variant='outline' size='sm' onClick={onCancelMnemonic}>
                <X className='h-4 w-4 mr-1' />
                Cancel
              </Button>
              <Button variant='default' size='sm' onClick={onSaveMnemonic}>
                <Check className='h-4 w-4 mr-1' />
                Save
              </Button>
            </div>
          </div>
        ) : isGeneratingMnemonic ? (
          <div className='p-3 bg-muted rounded-lg flex items-center'>
            <div className='animate-spin mr-2'>
              <RefreshCw className='h-4 w-4' />
            </div>
            <span>Generating mnemonic suggestion...</span>
          </div>
        ) : word.mnemonic ? (
          <p className='p-3 bg-muted rounded-lg'>{word.mnemonic}</p>
        ) : (
          <div className='p-3 bg-muted rounded-lg'>
            <p className='text-sm text-muted-foreground'>
              No mnemonic yet. Click "Generate" to create one or "Edit" to write
              your own.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
