import apiClient from '.';
import { FlashCard } from '../contexts/FlashCardsContext';

const fetchFlashCards = async (userId: string) => {
  const { data } = await apiClient.get(`/api/flashCards/user/${userId}`);

  return data.flashCards;
};

const addFlashCard = async (
  userId: string,
  word: string,
  translation: string,
) => {
  const { data } = await apiClient.post(`/api/flashCards/user/${userId}`, {
    word,
    translation,
  });

  return data.flashCard;
};

const deleteFlashCards = async (userId: string, flashCardIds: string[]) => {
  const { data } = await apiClient.delete(`/api/flashCards/user/${userId}`, {
    data: { flashCardIds },
  });

  return data.flashCard;
};

const updateFlashCard = async (flashCardId: string, flashCard: FlashCard) => {
  const { data } = await apiClient.patch(`/api/flashCards/${flashCardId}`, {
    flashCard,
  });

  return data.flashCard;
};

const createMnemonic = async (
  flashCardId: string,
  word: string,
  prevMnemonic: string,
  nativeLanguage: string,
) => {
  const { data } = await apiClient.post(
    `/api/flashCards/${flashCardId}/mnemonic`,
    { word, prevMnemonic, nativeLanguage },
  );

  return data.flashCard;
};

const createExampleSentence = async (
  flashCardId: string,
  word: string,
  prevExampleSentence: string,
) => {
  const { data } = await apiClient.post(
    `/api/flashCards/${flashCardId}/example-sentence`,
    { word, prevExampleSentence },
  );

  return data.flashCard;
};

const flashCardsAPI = {
  fetchFlashCards,
  addFlashCard,
  deleteFlashCards,
  updateFlashCard,
  createMnemonic,
  createExampleSentence,
};

export default flashCardsAPI;
