// import { FaGear } from 'react-icons/fa6';
import { STEPS_SELECTOR_CLASSES } from '../utils/constants';
import TuneIcon from '@mui/icons-material/Tune';

interface SettingsButtonProps {
  handleSettingsClick: () => void;
}

const SettingsButton = ({ handleSettingsClick }: SettingsButtonProps) => {
  return (
    <button
      onClick={handleSettingsClick}
      title='Language settings'
      className={`p-4 ${STEPS_SELECTOR_CLASSES.SETTINGS_BUTTON}`}
    >
      <TuneIcon />
    </button>
  );
};

export default SettingsButton;
