import { useTeachersContext } from '../contexts/TeachersContext';
import { useUserContext } from '../contexts/UserContext';
import LanguageDropdown from '../components/LanguageDropdown';
import HamburgerButton from '../components/HamburgerButton';
import Teacher from '../components/Teacher';

const TeachersPage = () => {
  const { user } = useUserContext();
  const { teachersByLanguageMap } = useTeachersContext();

  return (
    <div className='bg-gray-200 h-full'>
      <TeachersHeader />
      {teachersByLanguageMap[user.selectedLanguage].map(
        ({ name, age, bio, _id }) => (
          <Teacher {...{ name, age, bio, _id, key: _id }} />
        ),
      )}
    </div>
  );
};

const TeachersHeader = () => (
  <div className='w-full grid grid-cols-6 items-center py-3 bg-gray-100'>
    <div>
      <HamburgerButton />
    </div>
    <div className='col-start-2 col-span-4 flex justify-center'>
      <LanguageDropdown />
    </div>
  </div>
);

export default TeachersPage;
