import { ChangeEvent, useState } from 'react';
import { useUserContext } from '../contexts/UserContext';
import userAPI from '../api/user/userAPI';
import { getFlagAndLanguage } from '../utils/utils';
import { SIZES } from '../utils/constants';
import useIsWindows from '../hooks/useIsWindows';
import Spinner from './Spinner';
import toast from 'react-hot-toast';

const LanguageDropdown = () => {
  const { user, updateUser } = useUserContext();
  const [isChanging, setIsChanging] = useState(false);
  const isWindows = useIsWindows();

  const handleLanguageChange = async ({
    target,
  }: ChangeEvent<HTMLSelectElement>) => {
    setIsChanging(true);
    try {
      const updatedUser = await userAPI.updateUser({
        userEmail: user.email,
        selectedLanguage: target.value,
      });
      updateUser(updatedUser);
    } catch (error) {
      toast.error('Error updating language. Please try again.');
    }
    setIsChanging(false);
  };

  if (isChanging) {
    return (
      <div className='p-2'>
        <Spinner size={SIZES.MEDIUM} />
      </div>
    );
  }

  return (
    <select
      value={user.selectedLanguage}
      onChange={handleLanguageChange}
      className='rounded-xl bg-white p-3 h-12 text-gray-700 shadow'
    >
      {user.settings.map(({ language }) => (
        <option key={language} value={language}>
          {isWindows ? language : getFlagAndLanguage(language)}
        </option>
      ))}
    </select>
  );
};

export default LanguageDropdown;
