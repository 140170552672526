import classNames from 'classnames';
import { TfiThought } from 'react-icons/tfi';
import { useWidgetContext } from '../contexts/WidgetContext';

interface TopicSuggestionsButtonProps {
  isDisabled: boolean;
}

const TopicSuggestionsButton = ({
  isDisabled,
}: TopicSuggestionsButtonProps) => {
  const { openTopicSuggestions } = useWidgetContext();

  return (
    <button
      type='button'
      title='Open topic suggestions'
      disabled={isDisabled}
      onClick={openTopicSuggestions}
      className={classNames(
        'bg-blue-700 hover:bg-blue-800 text-white p-3 rounded-xl w-10 h-10 flex items-center justify-center',
        isDisabled && 'opacity-50 cursor-not-allowed',
      )}
    >
      <TfiThought />
    </button>
  );
};

export default TopicSuggestionsButton;
